




























































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// import Videos from 'video.js'
import LivePlayer from "@liveqing/liveplayer";

import { BigData } from "@/common/Views/bigData/bigData";
import EZUIKit from "ezuikit-js";
import BigScreenVideoAdressVue from "@/components/BigScreenVideoAdressVue.vue";
import BigScreenVideoListVue from "@/components/BigScreenVideoListVue.vue";
import { getBigScreenGovernmentysVideoList } from "@/api/index";
import { BasicsClass } from "@/common/BasicsClass";

@Component({
    components: {
        LivePlayer,
        BigScreenVideoAdressVue,
        BigScreenVideoListVue,
    },
})
export default class NewTop extends Vue {
    //视频播放列表
    @Prop() videoList: any;
    @Prop() VideoListData: any;
    @Prop() VideoGroupCodeData: any;
    @Prop() VideolaunchData: any;
    @Prop() videoSource: any;
    @Prop() ysVideoGroupTreeData: any;
    @Prop() isYsvideo: any;
    @Prop() showYSVideoFlag: any;
    //元素封面图        :poster="poster"

    //判断list是否为空 如果为空那么此值将变为false
    private NoVideo: boolean = false;

    //轮播图高度
    private carousel: number = 0;

    //是否显示蒙版
    private showDingVideo: boolean = false;

    //全屏url
    private BigUrl: string = "";

    //是否进行视频加载
    private bLoading: boolean = false;

    //是否显示暂无
    private IsNoDev: boolean = false;

    //显示大屏列表
    private ShowVideoListFlag: boolean = false;

    // private aspect:any = 'fullscreen'

    //点击播放
    private toPlayerVideo(index: number, indexs: number) {
        //获取index  判断index 获取设备号  获取播放地址
        //设置高度
        // setTimeout(() =>{
        //     let BigVideoRef:any = document.getElementById('BigVideo')
        //     BigVideoRef.style.height = document.documentElement.clientHeight + 'px'

        // } , 500)

        //显示全屏
        this.showDingVideo = true;
        this.bLoading = true;
        console.log(this.videoList[index][indexs],'this.videoList[index][indexs].deviceCode');
        //获取 赋值 url
        this.getVideoUrl(this.videoList[index][indexs]);
    }

    //点击关闭
    private closeVideo() {
        this.showDingVideo = false;
    }

    private mp4URL: string = "";
    private lastErrorTime: any = new Date(); // 错误发生时间，节流，3s内重新请求后台一次
    private autoplay: boolean = true; // 控制播放器是否自动播放
    private isError: boolean = false; // 播放错误标识
    private showControl: boolean = false; // 控制是否显示 视频控制条
    // 断线重连请求
    private failedPlay(e: any) {
        // this.mp4URL = 'http://172.168.1.13/test.flv'
    }

    private livestockCount: any = "";
    private inventoryCount: any = "";
    private page: any = {
        pageSize: 1,
        pageNo: 1,
    };
    //获取单个视频地址
    private player = "";
    private token = "";
    private primary =""
    private getVideoUrl(item: any) {
        console.log(this.$props.showYSVideoFlag,6546541321321)
        console.log(item,6546541321321)
        if (this.$props.showYSVideoFlag) {
            this.primary = "live";
            this.getYSToken(item);
            
        } else {
            new BigData().OneceVideoUrl(item.deviceCode, (data: any) => {
                this.BigUrl = data.data.url == "" ? null : data.data.url;
                this.livestockCount = data.data.livestockCount;
                this.inventoryCount = data.data.inventoryCount;
            });
        }
    }
    private getYSToken(item:any) {
        let form: any = {
            deviceCode: item.deviceCode,
        };
        new BasicsClass().BasicGet(
            "/animal/web/thirdMonitorChack/getToken",
            {
                monitorConfigId: item.configId,
            },
            false,
            true,
            (res: any) => {
                console.log(res);
                // this.player2.accessToken = res.data
                this.token = res.data;
                getBigScreenGovernmentysVideoList(
                form,
                this.page,
                (data: any) => {
                    this.player = new EZUIKit.EZUIKitPlayer({
                        id: "video-contaniner",
                        accessToken: this.token,
                        url: data.data[0].url,
                    });
                }
            );
                // this.getVideoUrl();
            }
        );
    }
    mounted() {
        let videoDom: any = this.$refs.VideoBox;
        let dom: any = this.$refs.NthTwoChild;
        console.log(this.$props.videoList), "this.$props.videoList";
        if (dom && dom.style) {
            dom.style.height = videoDom.clientHeight - 50 + "px";
        }
        this.carousel = videoDom.clientHeight - 50;

        let ItemVideoBoxArr: any = document.getElementsByClassName(
            "ItemVideoBox"
        );
        for (let i = 0; i < ItemVideoBoxArr.length; i++) {
            ItemVideoBoxArr[i].style.height = videoDom.clientHeight - 50 + "px";
        }
    }

    //上一张
    prev() {
        let Obj: any = this.$children[0];
        Obj.prev();
        this.$emit("prev", "");
    }

    //下一张
    next() {
        let Obj: any = this.$children[0];
        Obj.next();
        this.$emit("next", "");
    }

    @Watch("videoList")
    private changeList(newVal: []) {
        let btnDom: any = document.getElementsByClassName(
            "vjs-big-play-button"
        );
        for (let i = 0; i < btnDom.length; i++) {
            btnDom[i].style.width = 80 + "px";
            btnDom[i].style.height = 40 + "px";
        }
    }

    private ShowFixedBlock() {
        //点击更多 显示弹出层
        //先获取视频列表 中是否有视频  如果有一个 那么就显示弹出层  如果视频列表为空那么就进行一个提示 无更多视频
        if (this.videoList[0]) {
            this.$emit("getVideoList", ""); //如果有数据那么就告诉父组件 请求数据  此次请求  是根据父组件的数据进行请求
            this.ShowVideoListFlag = true;
        } else {
            this.$message({
                message: this.$i18n.t(
                    "breeding_module.screen.No_more_videos"
                ) as string,
                type: "warning",
            });
            return;
        }
        this.$nextTick(() => {
            let dom: HTMLElement | null = document.getElementById(
                "ShowVideoList"
            );
            dom ? (dom.style.height = document.body.clientHeight + "px") : "";
        });
    }

    private closeVideoList() {
        this.ShowVideoListFlag = false;
        this.$emit("ClearObj", {});
    }

    @Watch("ShowVideoListFlag")
    private ShowVideoListFlagChange(newVal: boolean) {
        this.$emit("ShowVideoListFlagChange", newVal);
    }
    @Watch("showDingVideo")
    private showDingVideoChange(newVal: boolean) {
        this.$emit("showDingVideoChange", newVal);
    }

    private SetVideoSendData(val: any) {
        this.$emit("SetFatherVideoSendData", val);
    }
    private SetYSVideoSendData(val: any) {
        this.$emit("SetYSVideoSendData", val);
    }

    private getNextPage() {
        this.$emit("getNextPage", "");
    }
    private getPrevPage() {
        this.$emit("getPrevPage", "");
    }
    private ShowBigScreenVideo(item: any) {
        //显示全屏
        this.showDingVideo = true;
        this.bLoading = true;
        console.log(this.showDingVideo);
        this.getVideoUrl(item);
    }
}
